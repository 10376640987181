import React from 'react';

import Formatting from './Formatting';

const Header = ({data}) => {

    return (
      <header>
          <div className="label" ><svg xmlns="http://www.w3.org/2000/svg" width="50px" viewBox="0 0 234.1 241.99">
            <path d="M7213,7190.57a28.23,28.23,0,0,0-5.92,9.08,28.82,28.82,0,0,0,0,21.45,28.23,28.23,0,0,0,5.92,9.08l19.26,19.85,19.25-19.85a28.08,28.08,0,0,0,5.91-9.09,28.77,28.77,0,0,0,0-21.43,28.08,28.08,0,0,0-5.91-9.09,27.12,27.12,0,0,0-8.83-6.07,26.44,26.44,0,0,0-20.84,0A27.06,27.06,0,0,0,7213,7190.57Zm19.51,29.28a9.12,9.12,0,0,1-5.2-1.63,9.53,9.53,0,0,1-3.44-4.33,9.9,9.9,0,0,1-.51-5.57,9.69,9.69,0,0,1,2.59-4.91,9.27,9.27,0,0,1,4.81-2.6,9.14,9.14,0,0,1,5.41.6,9.46,9.46,0,0,1,4.16,3.59,9.83,9.83,0,0,1-1.26,12.08A9.2,9.2,0,0,1,7232.46,7219.85Z" transform="translate(-7082.95 -7084.82)" fill="#ff6c48"/>
            <path d="M7217,7097.17a40.34,40.34,0,0,0-58.1,0,43.15,43.15,0,0,0,0,59.76l29.05,29.88,29-29.88a43.17,43.17,0,0,0,0-59.76Z" transform="translate(-7082.95 -7084.82)" fill="#47d080"/>
            <path d="M7154.48,7188.09a20.34,20.34,0,0,0-29,0,21.78,21.78,0,0,0,0,29.88l14.57,15,14.56-15a21.78,21.78,0,0,0,0-29.88Z" transform="translate(-7082.95 -7084.82)" fill="#3b96ff"/>
            <path d="M7275.08,7140.71a15.08,15.08,0,0,0-4.89-3.37,14.72,14.72,0,0,0-11.55,0,15.08,15.08,0,0,0-4.89,3.37,15.46,15.46,0,0,0-3.28,5,15.92,15.92,0,0,0,0,11.88,15.46,15.46,0,0,0,3.28,5l10.66,10.93,10.67-10.93a15.6,15.6,0,0,0,3.27-5,15.92,15.92,0,0,0,0-11.88A15.6,15.6,0,0,0,7275.08,7140.71Z" transform="translate(-7082.95 -7084.82)" fill="#f5c469"/>
            <path d="M7113.49,7301.91v9.27a20.08,20.08,0,0,1-9.79,2.38,19.7,19.7,0,0,1-8-1.48,20,20,0,0,1-6.8-4.61,20.82,20.82,0,0,1-4.48-7,21.43,21.43,0,0,1-1.43-8.24,21.21,21.21,0,0,1,1.47-8.71,20.75,20.75,0,0,1,4.88-7.28,20.12,20.12,0,0,1,14.53-5.33,19.6,19.6,0,0,1,9.74,2.5v9.41a12.89,12.89,0,0,0-4.23-3.33,12.65,12.65,0,0,0-5.19-1.25,12.41,12.41,0,0,0-5.23,1,12.76,12.76,0,0,0-4.36,3.14,14.72,14.72,0,0,0-3.66,10,13.42,13.42,0,0,0,.92,5.54,13,13,0,0,0,3.12,4.61,13.29,13.29,0,0,0,9.38,3.76,11.14,11.14,0,0,0,5.07-1.15A11.65,11.65,0,0,0,7113.49,7301.91Z" transform="translate(-7082.95 -7084.82)" fill="#081624"/>
            <path d="M7119.53,7312.65V7267h7.25v22.7a10.4,10.4,0,0,1,3.31-3,10,10,0,0,1,8.58-.57,10.23,10.23,0,0,1,3.65,2.52,11.8,11.8,0,0,1,2,7.44v16.6H7137v-14.39a7.72,7.72,0,0,0-1.37-5.2,4.48,4.48,0,0,0-1.55-1,4.36,4.36,0,0,0-1.82-.3,4.88,4.88,0,0,0-2.53.55,5,5,0,0,0-1.92,1.77,9.76,9.76,0,0,0-1.12,5.12v13.48Z" transform="translate(-7082.95 -7084.82)" fill="#081624"/>
            <path d="M7169.76,7304.18l6.66,1.09a11.82,11.82,0,0,1-1.09,2.16,20.11,20.11,0,0,1-2.12,2.66,10,10,0,0,1-3.86,2.51,15.82,15.82,0,0,1-5.57.93,13.27,13.27,0,0,1-5.29-.85,13.57,13.57,0,0,1-4.56-2.88,13.34,13.34,0,0,1-3.07-4.71,13.54,13.54,0,0,1-.89-5.6,14.52,14.52,0,0,1,.91-5.62,14.11,14.11,0,0,1,3-4.78,13,13,0,0,1,4.46-2.86,12.67,12.67,0,0,1,5.2-.84,12,12,0,0,1,5.51,1,12.31,12.31,0,0,1,4.52,3.36,16,16,0,0,1,3,10.44v.61h-19.22a7.47,7.47,0,0,0,1.24,4.46,6.16,6.16,0,0,0,2.28,1.93,5.94,5.94,0,0,0,2.9.57,6.26,6.26,0,0,0,3.48-.88A6.49,6.49,0,0,0,7169.76,7304.18Zm-12-8.24h12.15a6,6,0,0,0-.69-2.19,5.88,5.88,0,0,0-1.46-1.75,6.22,6.22,0,0,0-3.94-1.36,6.06,6.06,0,0,0-3.88,1.38A6.13,6.13,0,0,0,7157.74,7295.94Z" transform="translate(-7082.95 -7084.82)" fill="#081624"/>
            <path d="M7201.5,7287v7.33a8.3,8.3,0,0,0-5.93-2.32,6.55,6.55,0,0,0-2.9.48,6.72,6.72,0,0,0-2.44,1.68,7.68,7.68,0,0,0-1.89,5.33,7.19,7.19,0,0,0,4.58,6.95,7.24,7.24,0,0,0,2.91.51,8,8,0,0,0,5.67-2.32v7.25a14,14,0,0,1-6.63,1.65,13.3,13.3,0,0,1-5.33-.9,13.75,13.75,0,0,1-4.57-2.93,13.56,13.56,0,0,1-3.11-4.61,13.7,13.7,0,0,1-1-5.52,13.89,13.89,0,0,1,.88-5.49,13.54,13.54,0,0,1,2.95-4.66,13.12,13.12,0,0,1,4.52-3.06,12.81,12.81,0,0,1,5.33-.94A15.63,15.63,0,0,1,7201.5,7287Z" transform="translate(-7082.95 -7084.82)" fill="#081624"/>
            <path d="M7207,7267h7.22v28.91l9.62-9.62h9.38l-12,11.59,12.95,14.82h-9.61l-9.56-11.27-.78.72v10.66H7207Z" transform="translate(-7082.95 -7084.82)" fill="#081624" />
            <path d="M7244.56,7309.24v17.56h-7.23v-40.56h7.23v3.41a9.69,9.69,0,0,1,3.65-3.23,9.45,9.45,0,0,1,4.72-1,11.48,11.48,0,0,1,5,1,11.71,11.71,0,0,1,4.12,3.1,14.62,14.62,0,0,1,3.5,10.05,14.47,14.47,0,0,1-3.6,10.1,12,12,0,0,1-4.19,3,11.78,11.78,0,0,1-5,1,9.4,9.4,0,0,1-4.64-1.13A9.53,9.53,0,0,1,7244.56,7309.24Zm11.63-15.14a7.21,7.21,0,0,0-10.21,0,7.38,7.38,0,0,0-1.53,2.46,8.57,8.57,0,0,0,0,5.74,7.36,7.36,0,0,0,1.52,2.46,7.11,7.11,0,0,0,10.21,0,7.7,7.7,0,0,0,2-5.33,7.8,7.8,0,0,0-.48-2.87A7.63,7.63,0,0,0,7256.19,7294.1Z" transform="translate(-7082.95 -7084.82)" fill="#081624"/>
            <path d="M7284.69,7285.39a14.43,14.43,0,0,1,10.36,3.7,13.38,13.38,0,0,1,3.29,4.7,13.71,13.71,0,0,1,1,5.7,13.47,13.47,0,0,1-1.05,5.64,13,13,0,0,1-3.28,4.64,16.29,16.29,0,0,1-20.72,0,13,13,0,0,1-3.28-4.64,13.47,13.47,0,0,1-1.05-5.64,13.71,13.71,0,0,1,1-5.7,13.38,13.38,0,0,1,3.29-4.7A14.44,14.44,0,0,1,7284.69,7285.39Zm-5.18,19.32a7.23,7.23,0,0,0,3.73,2.07,7.1,7.1,0,0,0,4.22-.43,7.36,7.36,0,0,0,3.26-2.79,8,8,0,0,0,.68-7.06,7.39,7.39,0,0,0-1.55-2.45,7.21,7.21,0,0,0-10.37,0,7.6,7.6,0,0,0-2.07,5.33,7.5,7.5,0,0,0,.51,2.89A7.2,7.2,0,0,0,7279.51,7304.71Z" transform="translate(-7082.95 -7084.82)" fill="#081624"  />
            <path d="M7317.05,7292.58h-5v20.07h-7.23v-20.07H7302v-6.34h2.85v-9h7.23v9h5Z" transform="translate(-7082.95 -7084.82)" fill="#081624" /></svg>
          </div>
          {data.grups_name && <>
            <div className="--name" >{data.grups_name}</div>
            <div className="--date" >{Formatting.Date(data.date_posting, 'dd.mm.yyyy HH:MM')}</div>
           </>}

      </header>
    );
}

export default Header;

//<div className="avatar" />
//<div className="email" >
