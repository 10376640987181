import React, { Suspense  } from 'react';
import Store from './Store';

import Api from './Api';
import './css/App.css';
import './fonts/iconfont.css';


import Header from './component/Header';
import Modal from './component/Modal';

Store.load();

const App = () => {

  let page = Store.usePage()
  let [data,setData] = React.useState({});

  const Pages = React.lazy(() => import(`./pages/${page}`));

  const load = async (hash) => {
      let a = await Api.Init({ hash });
      if(a.status === "error") return Store.setPage("Error");
      setData(a.data)
      Store.setSSID(a.ssid);
      Store.setPage(a.page);
  }

  React.useEffect(()=>{
     if(window.location.hash !== ""){
         load(window.location.hash)
     }
  },[]);

  return (<Suspense fallback={<></>}>
              <Header data={data} />
              <Modal.Init />
              <Pages />
          </Suspense>)
}

export default App;
