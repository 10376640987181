import React from 'react'
import Store from './Store';
import Notice from './component/Notice';
const Api = {};

Api.url = "https://api.checkpot.tw-team.com/info";

Api.image = (name) => {
    return 'https://api.checkpot.tw-team.com/image/'+name;
}

Api.Init = async (j) => {
    try {
        let res = await fetch(Api.url+'/v1/init', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8"}});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        return { 'status': 'error' };
    }
}

Api.Winners = async (j) => {
    try {
        let res = await fetch(Api.url+'/v1/winners', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID() }});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        return { 'status': 'error' };
    }
}

Api.Ticket = async (j) => {
    try {
        let res = await fetch(Api.url+'/v1/ticket', {method: 'post', body: JSON.stringify(j), headers: { "Content-type": "application/json;charset=utf-8", 'ssid': Store.getSSID() }});
        res = await res.json();
        if(res.status === 'logaut') return Store.logaut();
        return res;
    } catch (e) {
        return { 'status': 'error' };
    }
}

export default Api;
